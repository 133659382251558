// .storybook/YourTheme.js

import { create } from '@storybook/theming';
import logoUrl from '../public/assets/images/logo/simpal.png';

export default create({
    base: 'light',
    brandTitle: 'SIMPAL storybook',
    brandUrl: 'https://simpal.heypal.de/',
    brandImage: logoUrl,
    appBg: 'white',
});
